/*
 * @Author: zhao - 🍉
 * @Date: 2021-11-09 10:39:12
 * @LastEditTime: 2021-11-11 15:00:21
 * @LastEditors: zhao - 🍉
 * @Description:
 * @FilePath: /xigua-blog-admin/src/services/account.ts
 */

import request from 'src/utils/request';

/**
 * 账号-账号列表
 *
 * @export
 * @param {{ page: number, pageSize?: number, keyword?: string, status: 0 }} data
 */
export function fetchAccountList(data: any, option = {}) {
  return request({ url: '/account/list', data, ...option });
}

/**
 * 账号-获取账号详情
 *
 * @export
 * @param {{id: number}} data
 */
export function fetchAccountGet(data: any, option = {}) {
  return request({ url: '/account/detail', data, ...option });
}

/**
 * 账号-更新账号
 *
 * @export
 * @param {*} data
 */
export function fetchAccountUpdate(data: any, option = {}) {
  return request({ url: '/account/update', data, ...option });
}

/**
 * 账号-新增账号
 *
 * @export
 * @param {*} data
 */
export function fetchAccountAdd(data: any, option = {}) {
  return request({ url: '/account/create', data, ...option });
}

/**
 * 账号-删除账号
 *
 * @export
 * @param {{id: number}} data
 */
export function fetchAccountDelete(data: any, option = {}) {
  return request({ url: '/account/delete', data, ...option });
}

/**
 * 账号-退出登录
 *
 * @export
 * @param {{id: number}} data
 */
export function fetchAccountLogout(data: any, option = {}) {
  return request({ url: '/account/login_out', data, ...option });
}
