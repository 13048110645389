import { Form, Input, Button, Select } from 'antd';
import React from 'react';
import DatePicker from 'src/components/Antd/AntdDatePicker';
import AntdInput from 'src/components/Antd/AntdInput';
import {
  TypeTableSearchProps,
  TypeTableSearchStates,
  TypeTableSearchFormItem,
} from 'src/typing';

class TableSearch extends React.Component<
  TypeTableSearchProps,
  TypeTableSearchStates
> {
  constructor(props: TypeTableSearchProps) {
    super(props);
    this.state = {
      formData: {},
    };
  }

  setFormData(data = {}) {
    this.setState((state: TypeTableSearchStates) => {
      state.formData = { ...state.formData, ...data };
      return state;
    });
  }

  getFormData() {
    return this.state.formData;
  }

  renderInput(item: TypeTableSearchFormItem) {
    const onchange = (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setFormData({ [item.key]: e.target.value });
    };
    const value = this.state.formData[item.key] as string;
    return (
      <Form.Item
        key={item.key}
        style={{ width: item.width || this.props.width || 140 }}
      >
        <Input
          value={value}
          placeholder={item.placeholder || '请输入'}
          onInput={onchange}
        />
      </Form.Item>
    );
  }

  renderNumber(item: TypeTableSearchFormItem) {
    const onchange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      this.setFormData({ [item.key]: value });
    };
    const value = this.state.formData[item.key]
      ? (this.state.formData[item.key] as string)
      : '';
    return (
      <Form.Item
        key={item.key}
        style={{ width: item.width || this.props.width || 140 }}
      >
        <AntdInput
          number
          value={value}
          placeholder={item.placeholder || '请输入'}
          onChange={onchange}
        />
      </Form.Item>
    );
  }

  renderSelect(item: TypeTableSearchFormItem) {
    const onChange = (e: string) => {
      this.setFormData({ [item.key]: e });
    };
    const value = this.state.formData[item.key] as string;
    return (
      <Form.Item
        key={item.key}
        style={{ width: item.width || this.props.width || 140 }}
      >
        <Select
          value={value}
          onChange={onChange}
          placeholder={item.placeholder || '请选择'}
        >
          {(item.options || []).map((e) => (
            <Select.Option key={e.key} value={e.key}>
              {e.value}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  }

  renderDate(item: TypeTableSearchFormItem) {
    const value = this.state.formData[item.key] as string;
    const onChange = (_date: void, dateString: string) => {
      this.setFormData({ [item.key]: dateString });
    };
    return (
      <Form.Item
        key={item.key}
        style={{ width: item.width || this.props.width || 140 }}
      >
        <DatePicker
          placeholder={item.placeholder || '请选择'}
          value={value}
          onChange={onChange}
        />
      </Form.Item>
    );
  }

  renderFormItem(list: Array<TypeTableSearchFormItem> = []) {
    return list.map((e) => {
      if (e.hide) return null;
      switch (e.type) {
        case 'input':
          return this.renderInput(e);
        case 'number':
          return this.renderNumber(e);
        case 'select':
          return this.renderSelect(e);
        case 'date':
          return this.renderDate(e);
        case 'datetime':
          return this.renderInput(e);
        default:
          return null;
      }
    });
  }

  renderAction() {
    const onSubmit = () => {
      const value = this.getFormData();
      if (this.props.onSubmit) {
        this.props.onSubmit(value);
      }
    };
    const onReset = () => {
      this.setState({ formData: {} }, () => {
        if (this.props.onSubmit) {
          this.props.onSubmit({});
        }
        if (this.props.onReset) {
          this.props.onReset({});
        }
      });
    };
    return (
      <Form.Item>
        <Button type="primary" onClick={onSubmit} style={{ marginRight: 16 }}>
          查询
        </Button>
        <Button onClick={onReset}>重置</Button>
      </Form.Item>
    );
  }

  render() {
    const styles = {
      display: 'flex',
      justifyContent: 'space-between',
    };
    return (
      <div style={styles}>
        <Form layout="inline">
          {this.renderFormItem(this.props.options)}
          {this.renderAction()}
        </Form>

        {this.props.children}
      </div>
    );
  }
}

export default TableSearch;
