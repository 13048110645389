/*
 * @Author: zhao - 🍉
 * @Date: 2021-11-09 10:20:43
 * @LastEditTime: 2021-11-09 10:29:44
 * @LastEditors: zhao - 🍉
 * @Description:
 * @FilePath: /xigua-blog-admin/src/utils/sign.ts
 */
import md5 from 'md5'
import { APPID, APPSECRET } from 'src/common/config';
import { TypePropnameObject } from 'src/typing';
import { utilsParseType } from '.';

const parseParams = (params: TypePropnameObject = {}) => {
  return Object.keys(params)
    .filter((key) => utilsParseType(params[key]) !== 'undefined')
    .sort()
    .map((key) => key + params[key])
    .join('');
};

const sign = (params = {}) => {
  const paramsStr = parseParams({ ...params, appid: APPID });
  let resultStr = APPSECRET + paramsStr;
  resultStr = encodeURIComponent(resultStr);
  return md5(resultStr);
};

export default sign;
