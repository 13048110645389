/*
 * @Author: zhao - 🍉
 * @Date: 2021-09-03 14:41:17
 * @LastEditTime: 2021-12-01 20:54:14
 * @LastEditors: zhao - 🍉
 * @Description:
 * @FilePath: /xigua-blog-admin/src/layout/index.jsx
 */

import React from 'react';
import classNames from 'classnames/bind';
import { withRouter } from 'react-router-dom';
import LayoutHeader from './components/LayoutHeader';
import styles from './index.scss';
import LayoutSlide from './components/LayoutSlide';
import { connectStore } from 'src/store';
import AntdDrawer from 'src/components/Antd/AntdDrawer';

const cx = classNames.bind(styles);
class Layout extends React.Component {
  closeSlideMenu =() => {
    this.props.dispatch({type: 'app-setSlideMenuEnable' , payload: false})
  }
  openSlideMenu = () => {
    if(this.LayoutSlideRef.render() === null) return
    this.props.dispatch({type: 'app-setSlideMenuEnable' , payload: true})
  }
  render() {
    const { children } = this.props;
    const { isMobile, slideMenuEnable } = this.props.app
    return (
      <div className={cx('layout')}>
        <LayoutHeader onSlideChange={this.openSlideMenu} />
        <div className={cx('content')}>
          <div className={cx('slide-menu')}>

            {isMobile ?
             <AntdDrawer width={200}  closable={false}  visible={slideMenuEnable} onClose={this.closeSlideMenu} >
                <LayoutSlide
                  onMenuChange={this.closeSlideMenu}
                  getRef={ref => {
                    this.LayoutSlideRef = ref
                  }}
                />
              </AntdDrawer>
              :
              <LayoutSlide />
            }
          </div>
          <div className={cx('children')}>{children}</div>
        </div>
      </div>
    );
  }
}
export default withRouter(connectStore()(Layout));
