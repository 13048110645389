import TableSearch from 'src/components/TableSearch';
import React, { ReactElement, ReactChild, ReactNodeArray } from 'react';
import { Spin, Table, TablePaginationConfig, Tabs } from 'antd';
import { utilsParseType } from 'src/utils';
import {
  TypePropnameObject,
  TypeRequestResponse,
  TypeRequestResponseList,
  TypeTableHelperProps,
  TypeTableHelperState,
} from 'src/typing';

class TableHelper extends React.Component<
  TypeTableHelperProps,
  TypeTableHelperState
> {
  constructor(props: TypeTableHelperProps) {
    super(props);
    this.state = {
      loading: false,
      queryData: {},
      tabsData: {},
      pagination: {
        page: 1,
        pageSize: 10,
      },
      dataSource: [],
      total: 0,
    };
  }

  componentDidMount() {
    if (this.props.getRef) {
      this.props.getRef(this);
    }
    this.postDataSource();
  }

  handleQuery = (e: TypePropnameObject) => {
    this.setState(
      {
        queryData: e,
        pagination: { page: 1, pageSize: 10 },
      },
      () => this.postDataSource()
    );
  };

  handleTabsChange = (e:string, key: string) => {
    this.setState(state => {
        state.tabsData[key] = e
        return state
    }, () => this.postDataSource())
  }

  handleChange = (e: TablePaginationConfig) => {
    this.setState(
      (state) => {
        state.pagination.page = e.current as number;
        return state;
      },
      () => this.postDataSource()
    );
  };

  parseResponse(res: TypeRequestResponse) {
    const { total, list } = res.data as TypeRequestResponseList;
    return { total, dataSource: list };
  }

  parseRequestData(res: TypePropnameObject) {
    return res;
  }

  postDataSource() {
    const data = {
      ...this.state.queryData,
      ...this.state.tabsData,
      ...this.state.pagination,
    };

    this.setState({ loading: true });
    const parseRequestData =
      this.props.parseRequestData || this.parseRequestData;
    this.props
      .request(parseRequestData(data))
      .then((res) => {
        const parse = this.props.parseResponse || this.parseResponse;
        this.setState(parse(res.data));
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  renderSeachSlot() {
    const { children } = this.props;
    if (!children) return [];
    const result: ReactNodeArray = [];
    if (utilsParseType(children) === 'array') {
      result.concat(children as ReactChild[]);
    } else {
      result.push(children as ReactElement);
    }
    return result.filter((e) => {
      const current = e as ReactElement;
      return current.props.slot === 'search';
    });
  }

  render() {
    const pagination: TablePaginationConfig = {
      total: this.state.total,
      size: 'default',
      pageSize: this.state.pagination.pageSize,
      current: this.state.pagination.page,
      showTotal: () => '共' + this.state.total + '条',
      onShowSizeChange: (page: number, pageSize: number) => {
        this.setState({ pagination: { page, pageSize } }, () =>
          this.postDataSource()
        );
      },
    };

    return (
      <Spin spinning={this.state.loading}>
          {this.props.tabsOptions && (
            this.props.tabsOptions.map(e => {
                return (
                    <Tabs key={e.key} onChange={(ee) => this.handleTabsChange(ee, e.key)}>
                        {e.options.map(item => <Tabs.TabPane tab={item.value} key={item.key} />)}
                    </Tabs>
                )
            })

        )}
        {this.props.searchOptions && (
          <TableSearch
            options={this.props.searchOptions}
            onSubmit={this.handleQuery}
          >
            {this.renderSeachSlot()}
          </TableSearch>
        )}
        <Table
          style={{ marginTop: 16 }}
          rowKey="id"
          bordered
          size="small"
          columns={this.props.columns || []}
          dataSource={this.state.dataSource || []}
          pagination={pagination}
          onChange={this.handleChange}
          scroll={this.props.scroll}
          {...this.props.tableProps}
        />
      </Spin>
    );
  }
}

export default TableHelper;
