/*
 * @Author: zhao - 🍉
 * @Date: 2021-09-23 15:23:18
 * @LastEditTime: 2021-11-10 17:46:10
 * @LastEditors: zhao - 🍉
 * @Description:
 * @FilePath: /xigua-blog-admin/src/services/actionLog.js
 */

import request from 'src/utils/request';

export function fetchActionLogList(data) {
  return request({ url: '/operationLog/list', data });
}
