import React from 'react';
import classNames from 'classnames';

interface Props {
  icon: string | undefined;
  className: string | undefined;
}

const Icon = (props: Props) => {
  return (
    <span className={classNames(['iconfont', props.icon, props.className])} />
  );
};

export default Icon;
