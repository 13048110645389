/*
 * @Author: zhao - 🍉
 * @Date: 2021-09-04 15:22:40
 * @LastEditTime: 2021-12-05 16:50:04
 * @LastEditors: zhao - 🍉
 * @Description:
 * @FilePath: /xigua-blog-admin/src/pages/system/role/index.jsx
 */
import React from 'react';
import moment from 'moment';
import TableHelper from 'src/components/TableHelper';
import { fetchRoleDelete, fetchRoleList } from 'src/services/role';
import { mapAccountStatus } from 'src/common/dict';
import { Badge, Space, Tooltip, Popconfirm, Button, message } from 'antd';
import { FormOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import AuthButton from 'src/containers/AuthButton';
import RoleUpdate from './update';

class Role extends React.Component {
  handleUpdate = (id) => {
    this.RoleUpdateRef.open(id).then(() => {
      this.TableHelperRef.postDataSource();
    });
  };

  handleDelete = (id) => {
    this.postRuleDelete(id).then(() => {
      this.TableHelperRef.postDataSource();
    });
  };

  postRuleDelete(id) {
    return fetchRoleDelete({ id }).then(() => {
      message.success('删除成功');
    });
  }

  renderAction(record) {
    return (
      <Space>
        <AuthButton access="c-3-2">
          <Tooltip title="编辑">
            <FormOutlined
              className="a"
              onClick={() => this.handleUpdate(record.id)}
            />
          </Tooltip>
        </AuthButton>
        <AuthButton access="c-3-3">
          <Tooltip title="删除">
            <Popconfirm
              title="确认删除吗"
              onConfirm={() => this.handleDelete(record.id)}
            >
              <span className="a">
                <DeleteOutlined />
              </span>
            </Popconfirm>
          </Tooltip>
        </AuthButton>
      </Space>
    );
  }

  render() {
    const columns = [
      { title: '名称', dataIndex: 'name' },
      { title: '备注', dataIndex: 'remark' },
      {
        title: '状态',
        dataIndex: 'status',
        render: (text) => {
          const current = mapAccountStatus.find((e) => e.key === text);
          if (current) {
            return <Badge text={current.value} color={current.color} />;
          }
          return '';
        },
      },
      {
        title: '创建时间',
        dataIndex: 'createTime',
        render: (text) => {
          return moment(text).format('YYYY-MM-DD HH:mm');
        },
      },
      {
        title: '操作',
        width: 80,
        dataIndex: 'action',
        render: (text, record) => this.renderAction(record),
      },
    ];

    const searchOptions = [
      { key: 'keyword', type: 'input', placeholder: '名称' },
    ];

    return (
      <div style={{ padding: 20 }}>
        <TableHelper
          ref={(ref) => {
            this.TableHelperRef = ref;
          }}
          searchOptions={searchOptions}
          columns={columns}
          request={fetchRoleList}
          scroll={{x: 500}}
        >
          <div slot="search">
            <AuthButton access="c-3-1">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => this.handleUpdate()}
              >
                新增
              </Button>
            </AuthButton>
          </div>
        </TableHelper>

        <RoleUpdate
          ref={(ref) => {
            this.RoleUpdateRef = ref;
          }}
        />
      </div>
    );
  }
}
export default Role;
