/*
 * @Author: zhao - 🍉
 * @Date: 2021-11-04 09:48:37
 * @LastEditTime: 2021-11-10 16:39:53
 * @LastEditors: zhao - 🍉
 * @Description: Input 处理了
 * @FilePath: /xigua-blog-admin/src/components/Antd/AntdInput/index.tsx
 */

import React from 'react';
import { Input, InputProps } from 'antd';

interface Props extends InputProps {
  value: string;
  min?: number;
  max?: number;
  placeholder?: string;
  number?: boolean | undefined;
  int?: boolean | undefined;
}

class AntdInput extends React.Component<Props> {
  parseNumber = (value: string, prevValue = ''): string => {
    if (!value) return value; // 如果结果不存在 直接返回
    if (value === '-') return value; // 如果结果不存在 直接返回

    // mark 暂未处理有小数的情况

    const resultNumber = parseFloat(value); //
    if (Number.isNaN(resultNumber)) {
      return prevValue;
    }
    if (resultNumber > Number.MAX_SAFE_INTEGER) {
      return Number.MAX_SAFE_INTEGER.toString();
    }
    if (resultNumber < Number.MIN_SAFE_INTEGER) {
      return Number.MIN_SAFE_INTEGER.toString();
    }
    if (this.props.min !== undefined) {
      return Math.max(resultNumber, this.props.min).toString();
    }
    if (this.props.max !== undefined) {
      return Math.min(resultNumber, this.props.max).toString();
    }
    return resultNumber.toString();
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.number) {
      const prevValue = this.props.value;
      const value = this.parseNumber(e.target.value, prevValue);
      e.target.value = value;
    }

    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };

  render() {
    const props = { ...this.props };
    if (this.props.number) {
      props.type = 'text';
      props.number = undefined;
    }
    const placeholder =
      typeof this.props.placeholder === 'string' ? '' : '请输入';
    return (
      <Input
        {...props}
        value={this.props.value}
        placeholder={placeholder}
        onChange={this.handleChange}
      />
    );
  }
}
export default AntdInput;
