/*
 * @Author: zhao - 🍉
 * @Date: 2021-12-01 11:36:48
 * @LastEditTime: 2021-12-01 18:55:46
 * @LastEditors: zhao - 🍉
 * @Description:
 * @FilePath: /xigua-blog-admin/src/components/Antd/AntdDrawer/index.tsx
 */
import React, { ReactNode, useState } from "react";

interface Props {
  visible?: boolean
  children?: ReactNode
  onClose?: () => void
}

const AntdDrawer = (props: Props) => {
  const [animate, setAnimate] = useState(false)
  const visible = props.visible || animate
  const style: React.CSSProperties  = {
    display: visible ? 'block' : 'none',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 10,
    width: '100%',
    height:'100%',
    overflow: 'hidden',
    backgroundColor: 'rgba(0,0,0,0.5)',
  }
  const contentStyle: React.CSSProperties = {
    position: 'fixed',
    height: '100%',
    left: 0,
    top:0,
    transitionDelay: props.visible ? '0.1s' : '0',
    transform: animate ?  'translateX(-100%)' : 'translateX(0)' ,
    transition: 'transform .15s'
  }

  const close = () => {
    setAnimate(true)
    setTimeout(() => {
      setAnimate(false)
      props.onClose && props.onClose()
    }, 150);
  }
  return (
    <div style={style} onClick={close}>
      <div style={contentStyle}>
        {props.children}
      </div>
    </div>
  )
}

export default AntdDrawer
