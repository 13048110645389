/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-plusplus */
import React from 'react';
import { Switch, Checkbox } from 'antd';
import classNames from 'classnames/bind';

import { connectStore } from 'src/store';
import styles from './index.scss';
import { utilsDeepClone } from 'src/utils';

const cx = classNames.bind(styles);

class Right extends React.Component {
  handleChange(key, value) {
    let keys = this.getValue();
    const routes = this.getAppRoutes();
    if (value) {
      this.deepTree(routes, (e) => {
        // 选中所有子集

        if (
          e.access &&
          this.isIncludeArray(e.access, key) &&
          !keys.includes(e.access)
        ) {
          keys.push(e.access);
        }
      });
    } else {
      // 移除当前项和所有子项
      keys = keys.filter((e) => {
        if (e === key) return false;
        if (this.isIncludeArray(key, e, true)) return false;
        return true;
      });

      // 过滤该项的上级 (没有子项的情况)
      keys = this.filterChildren(keys, key);
    }
    if (this.props.onChange) {
      this.props.onChange(keys.join(','));
    }
  }

  getValue() {
    return this.props.value ? this.props.value.split(',') : [];
  }

  getChecked(access) {
    const keys = this.getValue();
    return keys.find((e) => e === access);
  }

  getAppRoutes() {
    const appRouter = this.props.app.routes[0];
    const checker = (list) => {
      return utilsDeepClone(list).filter(e => {
        if (e.hidden) return false
        if (e.children) {
          e.children = checker(e.children)
        }
        return true
      })
    }
    const result =  appRouter ? checker(appRouter.children) : [];
    return result;
  }

  deepTree(list, checker) {
    list.forEach((e) => {
      const child = e.children || e.actions;
      checker(e);
      if (child) {
        this.deepTree(child, checker);
      }
    });
  }

  isIncludeArray(key_1, key_2, isOrder) {
    const array_1 = key_1 ? key_1.split('-') : [];
    const array_2 = key_2 ? key_2.split('-') : [];
    const length = isOrder
      ? array_1.length
      : Math.min(array_1.length, array_2.length);
    let result = true;
    for (let i = 0; i < length; i++) {
      if (array_1[i] !== array_2[i]) {
        result = false;
      }
    }
    return result;
  }

  filterChildren(keys, key) {
    // 过滤掉 当前项的父项没有儿子
    const a = key.split('-');
    for (let i = a.length - 1; i >= 0; i--) {
      let childrenLength = 0;
      const current = a.slice(0, i).join('-');
      keys.forEach((eee) => {
        if (this.isIncludeArray(current, eee, true)) {
          childrenLength++;
        }
      });
      if (childrenLength <= 1) {
        keys = keys.filter((e) => e !== current);
      }
    }
    return keys;
  }

  render() {
    const appRouter = this.getAppRoutes();
    return (
      <div>
        {/* 一级循环 */}
        {appRouter.map((e) => (
          <div className={cx('route-item')} key={e.access}>
            <div className={cx('route-header', { border: e.children })}>
              <div>{e.title}</div>
              <Switch
                checked={this.getChecked(e.access)}
                onChange={(checked) => this.handleChange(e.access, checked)}
              />
            </div>
            {/* 二级循环 */}
            {e.children?.map((ee) => (
              <div key={ee.access} className={cx('route-child-2-list')}>
                <div className={cx('label')}>
                  <Checkbox
                    checked={this.getChecked(ee.access)}
                    onChange={(event) =>
                      this.handleChange(ee.access, event.target.checked)
                    }
                  >
                    {ee.title}
                  </Checkbox>
                </div>
                <div className={cx('value')}>
                  {/* 三级循环 */}
                  {ee.actions?.map((eee) => (
                    <Checkbox
                      checked={this.getChecked(eee.access)}
                      onChange={(event) =>
                        this.handleChange(eee.access, event.target.checked)
                      }
                      key={eee.access}
                    >
                      {eee.title}
                    </Checkbox>
                  ))}
                </div>
              </div>
            ))}

            {e.actions?.map((eee) => (
              <Checkbox key={eee.access}>{eee.title}</Checkbox>
            ))}
          </div>
        ))}
      </div>
    );
  }
}
export default connectStore()(Right);
