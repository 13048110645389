/*
 * @Author: zhao - 🍉
 * @Date: 2021-11-09 10:30:06
 * @LastEditTime: 2021-11-09 10:33:04
 * @LastEditors: zhao - 🍉
 * @Description:
 * @FilePath: /xigua-blog-admin/src/utils/monitor.ts
 */
type Ifn = (arg0: unknown) => void;

interface IList {
  [propname: string]: Array<Ifn>;
}

class Monitor {
  list: IList = {};

  on(name: string, fn: Ifn) {
    this.list[name] = this.list[name] || [];
    if (!this.list[name].find((e) => e === fn)) {
      this.list[name].push(fn);
    }

    return () => {
      this.list[name] = this.list[name].filter((e) => e !== fn);
    };
  }

  emit(name: string, data: unknown) {
    this.list[name].forEach((fn) => fn(data));
  }
}
export default new Monitor();
