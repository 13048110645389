/*
 * @Author: zhao - 🍉
 * @Date: 2021-11-09 10:28:37
 * @LastEditTime: 2021-11-30 13:44:54
 * @LastEditors: zhao - 🍉
 * @Description:
 * @FilePath: /xigua-blog-admin/src/common/config.js
 */
export const APPID = 'xigua-blog'
export const APPSECRET = '123'
export const API_NAME = '/api/admin'
export const APP_ROUTER_ACCESS_ENABLE = true
export const APP_ACTION_ACCESS_ENABLE = true
