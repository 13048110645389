import React, { FC, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Button, Input, Select, Form, message, Spin } from "antd";
import Bytemd from "src/components/Bytemd";
import { fetchArticleCreate, fetchArticleInfo, fetchArticleUpdate } from "src/services/article";
import { i_article } from "src/typing";
import { fetchDictList } from "src/services/dict";

/*
 * @Author: zhao - 🍉
 * @Date: 2021-04-21 21:41:29
 * @LastEditors: zhao - 🍉
 * @LastEditTime: 2021-12-08 17:18:36
 * @Descripttion:
 */
const ArticleInfo: FC = () => {
  const params: {id?: string} = useParams()
  const history = useHistory()
  const [saveLoading, setSaveLoading] = useState(false)
  const [getLoading, setGetLoading] = useState(false)
  const [tagList, setTagList] = useState<Array<{id: number, name: string}>>([])

  const [form] = Form.useForm()

  useEffect(() => {
    postTagList()
    if (params.id && params.id !== '0') {
      postArticleGet(Number(params.id))

    }
  }, [params.id]);
  const postArticleGet = (id: number) => {
    setGetLoading(true)
    fetchArticleInfo({id}).then(res => {
      form.setFieldsValue({
        title: res.data.data.title,
        sub_title: res.data.data.sub_title,
        content: res.data.data.content,
        tags: res.data.data.tags,
      })
    }).finally(() => {
      setGetLoading(false)
    })
  }
  const postTagList = () => {
    fetchDictList({parent_id: 1}).then(res => {
      setTagList(res.data.data.list)
    })
  }
  const postArticleSubmit = (data: i_article) => {
    const post = data.id ? fetchArticleUpdate : fetchArticleCreate
    setSaveLoading(true)
    post(data).then(() => {
      history.goBack()
      message.success('保存成功')

    }).finally(() => {
      setSaveLoading(false)
    })
  }

  const handleSubmit = (e:any) => {
    if (params.id && params.id !== '0') {
      e.id = params.id
    }
    postArticleSubmit(e)
  }
  return <div className="container" style={{padding: 20}}>
    <Spin spinning={getLoading}>
      <Form labelCol={{span: 3}} form={form} wrapperCol={{span:18}} onFinish={handleSubmit}>
        <Form.Item label="标题" name="title" required>
          <Input />
        </Form.Item>
        <Form.Item label="副标题" name="sub_title">
          <Input  />
        </Form.Item>
        <Form.Item label="标签" name="tags">
          <Select mode="multiple">
              {tagList.map(e=> <Select.Option key={e.id} value={e.id}>{e.name}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item label="内容" name="content" required>
          <Bytemd  />
        </Form.Item>
        <Form.Item label=" " colon={false} style={{textAlign: 'center'}} >
          <Button type="primary" style= {{width: 230}}  htmlType="submit" loading={saveLoading}>保存</Button>
        </Form.Item>
      </Form>
    </Spin>
  </div>
}

export default ArticleInfo
