/*
 * @Author: zhao - 🍉
 * @Date: 2021-11-09 10:34:24
 * @LastEditTime: 2022-03-02 23:54:09
 * @LastEditors: zhao - 🍉
 * @Description:
 * @FilePath: /xigua-blog-admin/src/store/index.js
 */

import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import { connect } from 'react-redux';
import thunk from 'redux-thunk';
import user from './modules/user';
import app from './modules/app';

const reducer = combineReducers({ app, user });

const reduxDevtools = window.__REDUX_DEVTOOLS_EXTENSION__
  ? window.__REDUX_DEVTOOLS_EXTENSION__()
  : (f) => f;
const store = createStore(
  reducer,
  compose(applyMiddleware(thunk), reduxDevtools)
);

export const connectStore = (maActionToProps = {}) => {
  return (Component) => {
    if (!Component) return;
    const _mapStateToProps = (state) => state;
    const _maActionToProps = {
      dispatch: (dispatch) => dispatch,
      ...maActionToProps,
    };
    return connect(_mapStateToProps, _maActionToProps, null, {forwardRef: true})(Component);
  };
};

export default store;
