/*
 * @Author: zhao - 🍉
 * @Date: 2021-11-09 10:39:58
 * @LastEditTime: 2021-11-09 13:58:59
 * @LastEditors: zhao - 🍉
 * @Description:
 * @FilePath: /xigua-blog-admin/src/serveices/role.ts
 */

import request from 'src/utils/request';

/**
 * 角色-角色列表
 *
 * @export
 * @param {{ page: number, pageSize?: number, keyword?: string, status: 0 }} data
 */
export function fetchRoleList(data: any, option = {}) {
  return request({ url: '/role/list', data, ...option });
}

/**
 * 角色-获取角色详情
 *
 * @export
 * @param {{id: number}} data
 */
export function fetchRoleGet(data: any, option = {}) {
  return request({ url: '/role/detail', data, ...option });
}

/**
 * 角色-更新角色
 *
 * @export
 * @param {*} data
 */
export function fetchRoleUpdate(data: any, option = {}) {
  return request({ url: '/role/update', data, ...option });
}

/**
 * 角色-新增角色
 *
 * @export
 * @param {*} data
 */
export function fetchRoleAdd(data: any, option = {}) {
  return request({ url: '/role/create', data, ...option });
}

/**
 * 角色-删除角色
 *
 * @export
 * @param {{id: number}} data
 */
export function fetchRoleDelete(data: any, option = {}) {
  return request({ url: '/role/delete', data, ...option });
}

/**
 * 角色 - 更新权限点
 *
 * @export
 * @param {{ id: 0, rightStr: string }} data
 * @returns
 */
export function fetchRoleRightUpdate(data: any) {
  return request({ url: '/role/updateRightStr', data });
}
