/*
 * @Author: zhao - 🍉
 * @Date: 2021-12-31 17:34:58
 * @LastEditTime: 2021-12-31 17:41:16
 * @LastEditors: zhao - 🍉
 * @Description:
 * @FilePath: /xigua-blog-admin/src/services/file.ts
 */
import request from 'src/utils/request';

/**
 * 登录
 *
 * @export
 * @param {{ account: String, password: String }} data
 * @returns {{  id: 0, name: string, rightStr: string, token: string, validSeconds: 0, weixinLogo: string }}
 */
export function fetchFileUpload(data: any) {
  return request({ url: '/file/upload', data, headers: {'Content-Type': 'multipart/form-data'} });
}
