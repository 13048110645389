/*
 * @Author: zhao - 🍉
 * @Date: 2021-09-13 16:45:40
 * @LastEditTime: 2021-11-03 11:57:39
 * @LastEditors: zhao - 🍉
 * @Description:
 * @FilePath: /xigua-blog-admin/src/components/DatePicker/index.tsx
 */
import React from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';

interface Props {
  value?: string | number | Date;
  [propname: string]: unknown;
}

class DatePickerMoment extends React.Component<Props> {
  render() {
    const value = this.props.value ? moment(this.props.value) : null;
    const props = {
      ...this.props,
      value,
    };
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <DatePicker {...props} />;
  }
}

export default DatePickerMoment;
