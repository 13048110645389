/*
 * @Author: zhao - 🍉
 * @Date: 2021-11-12 17:51:21
 * @LastEditTime: 2021-11-12 17:59:34
 * @LastEditors: zhao - 🍉
 * @Description:
 * @FilePath: /xigua-blog-admin/src/common/helper.js
 */

export const parseDevice = (store) => {
  const isMobile = () => {
    const {innerWidth} = window
    const maxMobileWidth = 768
    return innerWidth <= maxMobileWidth
  }
  store.dispatch({type: 'app-setMobile', payload: isMobile()})
  window.onresize = () => {
    store.dispatch({type: 'app-setMobile', payload: isMobile()})
  }
}
