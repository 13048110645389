/*
 * @Author: zhao - 🍉
 * @Date: 2021-10-02 21:20:27
 * @LastEditTime: 2021-11-10 18:39:26
 * @LastEditors: zhao - 🍉
 * @Description:
 * @FilePath: /xigua-blog-admin/src/containers/AuthButton/index.tsx
 */
import React, { ReactChildren } from 'react';
import { APP_ROUTER_ACCESS_ENABLE } from 'src/common/config';
import { authGetUser } from 'src/utils/auth';

interface Props {
  access?: string;
  children?: ReactChildren;
}
class AuthButton extends React.Component<Props> {
  render() {
    const { rightStr = '' } = authGetUser();
    const rightList = rightStr.split(',');

    if (
      APP_ROUTER_ACCESS_ENABLE ||
      !this.props.access ||
      rightList.includes('all') ||
      rightList.includes(this.props.access)
    ) {
      return this.props.children;
    }

    return null;

    // 没有权限
    // const { componentType } = this.props;
    // if (!componentType || componentType === 'button') {
    //   return <Button>{this.props.children}</Button>;
    // }
    // if (componentType === 'link') {
    //   return <span>{this.props.children.props.children}</span>;
    // }
    // return this.props.children;
  }
}
export default AuthButton;
