/*
 * @Author: zhao - 🍉
 * @Date: 2021-04-21 20:46:06
 * @LastEditors: zhao - 🍉
 * @LastEditTime: 2021-11-30 16:02:01
 * @Descripttion:
 */
import { message } from 'antd';
import axios, { AxiosRequestConfig } from 'axios';
import { API_NAME, APPID } from 'src/common/config';
import { utilsNull2Empty, utilsParams2object } from '.';
import {  authGetUser } from './auth';
import monitor from './monitor';
import sign from './sign';

interface TypeAxiosRequestConfig extends AxiosRequestConfig {
  autoParse?: boolean;
}

const request = axios.create({
  timeout: 300000,
  baseURL: API_NAME,
  method: 'POST',
});

export const getDefaultHeader = () => {
  const user = authGetUser();
  return {
    accountId: user.id,
    appid: APPID,
    token: user.token || '',
  };
};

const parseRequestParams = (res: AxiosRequestConfig) => {
  const params = res.params || {};
  const data = utilsNull2Empty(res.data || {});
  const urlParams = utilsParams2object(res.url);
  return { ...params, ...data, ...urlParams };
};

request.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    ...getDefaultHeader(),
    sign: sign(parseRequestParams(config)),
  };
  config.validateStatus = (status) => {
    if (status === 401) return true;
    return status >= 200 && status < 300;
  };
  config.data = utilsNull2Empty(config.data);
  return config;
});

request.interceptors.response.use((res) => {
  if (res.status === 401) {
    monitor.emit('nav', '/login');
    return Promise.reject(new Error('无权访问'));
  }
  if (res.status !== 200) {
    throw new Error('请求失败');
  }
  const config = res.config as TypeAxiosRequestConfig;
  const { data } = res;
  if (config.autoParse === false) {
    return res;
  }
  if (res.data.code !== 0) {
    message.error(res.data.message);
    return Promise.reject(res.data.message);
  }

  return res

  // 这个先不返回data
  // return data;
});

export default request;
