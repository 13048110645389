/*
 * @Author: zhao - 🍉
 * @Date: 2021-11-09 10:36:25
 * @LastEditTime: 2022-03-03 00:19:25
 * @LastEditors: zhao - 🍉
 * @Description:
 * @FilePath: /xigua-blog-admin/src/store/modules/app.js
 */

import { utilsDeepClone } from "src/utils";

const initState = {
  routes: [],
  accessRoutes: [],
  isMobile: false,
  slideMenuEnable: false, // 此开关是控制侧边菜单是否显示
};

const filterRoutes = (routes = [], right) => {
  const rightList = right ? right.split(',') : [];
  // 如果权限串中包含 all 则是全部权限
  if (rightList.includes('all')) return routes;

  const deep = (list) => {
    return utilsDeepClone(list).filter((e) => {
      if (e.access && !rightList.includes(e.access)) {
        return false;
      }

      if (e.children) {
        e.children = deep(e.children);
      }

      return true;
    });
  };

  return deep(routes);
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'app-setMobile':
      return {...state, isMobile: action.payload}
    case 'app-setSlideMenuEnable':
      return {...state, slideMenuEnable: action.payload}
    case 'app-setRoutes':
      return { ...state, routes: action.payload };
    case 'app-setAccessRoutes':
      return {
        ...state,
        accessRoutes: filterRoutes(
          action.payload.routesConfig,
          action.payload.rightStr
        ),
      };
    default:
      return state;
  }
};

export default reducer;
