/*
 * @Author: zhao - 🍉
 * @Date: 2021-11-10 17:41:15
 * @LastEditTime: 2021-11-10 19:18:09
 * @LastEditors: zhao - 🍉
 * @Description:
 * @FilePath: /xigua-blog-admin/src/common/dict.js
 */
export const mapAccountStatus = [
  { key: 1, value: '有效', color: 'green' },
  { key: 2, value: '停用', color: 'gray' },
];
export const mapActionType = [
  { key: 1, value: '新增' },
  { key: 2, value: '修改' },
  { key: 3, value: '删除' },
  { key: 4, value: '浏览' },
  { key: 5, value: '其他' },
];

export const mapActionInfo = [
  { key: 1, value: '登录' },
  { key: 2, value: '系统参数' },
  { key: 3, value: '数据字典' },
  { key: 4, value: '用户管理' },
  { key: 5, value: '国别' },
  { key: 100, value: '其他' },
];
