import { Modal, Form, Input, Tooltip, message, Spin, Select } from 'antd';
import React from 'react';
import { MinusCircleOutlined } from '@ant-design/icons';
import { mapAccountStatus } from 'src/common/dict';
import {
  fetchAccountAdd,
  fetchAccountGet,
  fetchAccountUpdate,
} from 'src/services/account';
import { fetchRoleList } from 'src/services/role';
import { utilsNumber2boolean } from 'src/utils';

class AccountUpdate extends React.Component {
  constructor() {
    super();
    this.state = {
      visible: false,
      loading: false,
      roleList: [],
      passwrodUpdate: false,
      isShowProjectIds: false,
    };
  }

  componentDidMount() {
    this.postRoleList();
  }

  handleFormChange = (e) => {

  };

  handleSubmit() {
    this.FormRef.validateFields()
      .then((res) => {
        return this.postAccountSave(res);
      })
      .then(() => {
        this.close();
        this.monitor.resolve();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  setInitFormValue() {
    if (this.FormRef) {
      this.FormRef.setFieldsValue({
        status: 1,
      });
    } else {
      setTimeout(() => {
        this.setInitFormValue();
      }, 100);
    }
  }

  open(id) {
    return new Promise((resolve, reject) => {
      this.id = id;
      if (id) {
        this.postAccountGet(id);
      } else {
        this.setInitFormValue();
      }
      this.setState({ visible: true });
      this.monitor = { resolve, reject };
    });
  }

  close() {
    this.FormRef.resetFields();
    this.setState({ visible: false, passwrodUpdate: false });
  }

  postRoleList() {
    fetchRoleList({ page: 1, pageSize: 1000 }).then((res) => {
      this.setState({
        roleList: res.data.data.list,
      });
    });
  }

  postAccountGet(id) {
    this.setState({ loading: true });
    fetchAccountGet({ id })
      .then((res) => {
        this.FormRef.setFieldsValue({
          user_name: res.data.data.user_name,
          user_phone: res.data.data.user_phone,
          user_account: res.data.data.user_account,
          user_pass: res.data.data.user_pass,
          role_id: res.data.data.role_id,
          status: res.data.data.status,
        });
        this.oldPassword = res.data.data.password;
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  postAccountSave(data) {
    let post = null;
    if (this.id) {
      post = fetchAccountUpdate;
      data.id = this.id;
    } else {
      post = fetchAccountAdd;
    }
    return post(data).then((res) => {
      message.success('保存成功');
      return res.data;
    });
  }

  renderPassword() {
    const handleEdit = () => {
      this.setState({ passwrodUpdate: true });
      this.FormRef.setFieldsValue({ password: '' });
    };
    const handleCancel = () => {
      this.setState({ passwrodUpdate: false });
      this.FormRef.setFieldsValue({
        password: this.oldPassword,
      });
    };
    if (this.state.passwrodUpdate || !this.id) {
      return (
        <React.Fragment>
          <Form.Item label="密码" name="user_pass" rules={[{ required: true }]}>
            <div style={{ position: 'relative' }}>
              <Input.Password
                autoFocus
                visibilityToggle={false}
                placeholder="请输入"
              />
              {this.id && (
                <Tooltip title="取消更改">
                  <MinusCircleOutlined
                    onClick={handleCancel}
                    style={{
                      color: 'rgba(0,0,0,.45)',
                      position: 'absolute',
                      right: 10,
                      top: 10,
                      cursor: 'pointer',
                    }}
                  />
                </Tooltip>
              )}
            </div>
          </Form.Item>
        </React.Fragment>
      );
    }
    return (
      <Form.Item label="密码" name="user_pass" rules={[{ required: true }]}>
        <Input.Password
          onClick={handleEdit}
          visibilityToggle={false}
          placeholder="请输入"
        />
      </Form.Item>
    );
  }

  render() {
    const modalConfig = {
      title: '账号',
      visible: this.state.visible,
      onOk: this.handleSubmit.bind(this),
      onCancel: this.close.bind(this),
    };

    return (
      <Modal {...modalConfig}>
        <Spin spinning={this.state.loading}>
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 18 }}
            ref={(ref) => {
              this.FormRef = ref;
            }}
            onValuesChange={this.handleFormChange}
          >
            <Form.Item label="姓名" name="user_name" rules={[{ required: true }]}>
              <Input placeholder="请输入" />
            </Form.Item>
            <Form.Item label="手机" name="user_phone" rules={[{ required: true }]}>
              <Input placeholder="请输入" />
            </Form.Item>
            <Form.Item label="账号" name="user_account" rules={[{ required: true }]}>
              <Input placeholder="请输入" />
            </Form.Item>
            {this.renderPassword()}



            <Form.Item label="角色" name="role_id" rules={[{ required: true }]}>
              <Select placeholder="请选择">
                {this.state.roleList.map((e) => (
                  <Select.Option key={e.id} value={e.id}>
                    {e.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="状态" name="status" rules={[{ required: true }]}>
              <Select placeholder="请选择">
                {mapAccountStatus.map((e) => (
                  <Select.Option key={e.key} value={e.key}>
                    {e.value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    );
  }
}

export default AccountUpdate;
