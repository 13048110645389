/*
 * @Author: zhao - 🍉
 * @Date: 2021-09-03 14:21:22
 * @LastEditTime: 2022-03-02 23:59:13
 * @LastEditors: zhao - 🍉
 * @Description:
 * @FilePath: /xigua-blog-admin/src/router/index.jsx
 */

import React from 'react';
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  BrowserRouter,
} from 'react-router-dom';
import Layout from 'src/layout';
import Login from 'src/pages/login';
import { connectStore } from 'src/store';
import { authGetUser } from 'src/utils/auth';
import monitor from 'src/utils/monitor';
import routesConfig from './config';
const createRoute = (routes) => {
  const result = [];
  const deep = (list = [], parentPath = '') => {
    list.forEach((e) => {
      const path = parentPath ? parentPath + '/' + e.path : e.path;
      if (e.children) {
        deep(e.children, path);
      }
      if (e.component) {
        result.push(
          <Route
            key={path}
            path={path}
            exact={e.exact}
            component={e.component}
          />
        );
      }
    });
  };

  deep(routes);
  return result;
};

const LayoutWrap = (props) => {
  const his = useHistory();
  const user = authGetUser();
  if (user.token) {
    if (!props.user.token) {
      props.dispatch({ type: 'setUser', payload: user });
      props.dispatch({ type: 'app-setRoutes', payload: routesConfig });
      props.dispatch({
        type: 'app-setAccessRoutes',
        payload: { routesConfig, rightStr: user.rightStr },
      });
    }
  } else {
    his.push('/login');
  }
  monitor.on('nav', (e) => {
    his.push(e);
  });
  return (
    <Layout>
      <Switch>{createRoute(props.app.accessRoutes)}</Switch>
    </Layout>
  );
};

export default function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Redirect exact from="/" to="/app/article/list" />
        <Route path="/login" component={Login} />
        <Route path="/app" component={connectStore()(LayoutWrap)} />
      </Switch>
    </BrowserRouter>
  );
}
