/*
 * @Author: zhao - 🍉
 * @Date: 2021-04-21 21:09:27
 * @LastEditors: zhao - 🍉
 * @LastEditTime: 2021-04-21 21:10:19
 * @Descripttion: 
 */
import dayjs from "dayjs";

export const parseTime = (time: Date, format = 'YYYY-MM-DD HH:mm') => {
  return dayjs(time).format(format)
}