/*
 * @Author: zhao - 🍉
 * @Date: 2021-04-16 20:37:42
 * @LastEditors: zhao - 🍉
 * @LastEditTime: 2022-03-03 00:24:41
 * @Descripttion: 约束: 以 utils开头
 */


import moment from 'moment';
import {
  TypeClassNameArg,
  TypeMap,
  TypePropnameObject,
  TypeTree,
} from 'src/typing';

export const utilsParams2object = (parmas = '') => {
  if (!parmas) return {};
  if (parmas.indexOf('=') === -1) return {};
  const [url, splitParams] = parmas.split('?');
  const formatParams = splitParams || url;
  const result: TypePropnameObject = {};
  formatParams.split('&').forEach((e) => {
    const [key, value] = e.split('=');
    result[key] = value;
  });
  return result;
};

export const utilsObject2Params = (obj: TypePropnameObject = {}) => {
  return Object.keys(obj)
    .map((key) => key + '=' + obj[key])
    .join('&');
};

/**
 * number 转 boolean (1真 2假)
 *
 * @export
 * @param {*} data 数据值
 * @returns {boolean} 数据类型
 */
export const utilsNumber2boolean = (num: 1 | 2 | '1' | '2') => {
  if (typeof num === 'number') {
    return num === 1;
  }
  return parseFloat(num) === 1;
};

/**
 * boolean  转 number  (1真 2假)
 *
 * @export
 * @param {*} data 数据值
 * @returns {number} 数据类型
 */
export const utilsBoolean2number = (bool: boolean) => {
  return bool === true ? 1 : 2;
};

/**
 * 判断数据类型
 *
 * @export
 * @param {*} data 数据值
 * @returns {'string' | 'number' | 'boolean' | 'array' | 'object' | 'function' | 'null' | 'undefined' | 'symbol'} 数据类型
 */
export function utilsParseType(data: unknown) {
  const map: TypePropnameObject = {
    '[object String]': 'string',
    '[object Number]': 'number',
    '[object Boolean]': 'boolean',
    '[object Array]': 'array',
    '[object Object]': 'object',
    '[object Function]': 'function',
    '[object Null]': 'null',
    '[object Undefined]': 'undefined',
    '[object Symbol]': 'symbol',
  };
  const type = Object.prototype.toString.call(data);
  return map[type];
}

/**
 * 转换null为空字符串
 *
 * @export
 * @param {*} data 数据值
 * @returns {boolean} 数据类型
 */
export const utilsNull2Empty = <T>(val: T): T | string => {
  const type = utilsParseType(val);
  if (type === 'string') return val;
  if (type === 'number') return val;
  if (type === 'null') return '';
  if (type === 'undefined') return val;
  if (type === 'array' || type === 'object') {
    const valObject = val as unknown as TypePropnameObject;
    Object.keys(valObject).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(val, key)) {
        valObject[key] = utilsNull2Empty(valObject[key]);
      }
    });
    return valObject as unknown as T;
  }
  return val;
};

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} format
 * @returns {string | null}
 */
export function utilsParseTime(
  time: moment.MomentInput,
  format = 'YYYY/MM/DD HH:mm'
) {
  return time ? moment(time).format(format) : '-';
}

/**
 * 查找树
 *
 * @export
 * @param {Array} tree 树
 * @param {Function} checker 检查者
 * @returns {Array} 找出的集合
 */
export function utilsFindTree(
  tree: TypeTree = [],
  checker?: (arg0: unknown) => boolean
) {
  const result: TypeTree = [];
  const mapTree = (list: TypeTree) => {
    list.forEach((e) => {
      if (checker && checker(e)) {
        result.push(e);
      }
      if (e.children) {
        mapTree(e.children);
      }
    });
  };
  mapTree(tree);
  return result;
}

export const  utilsMapTree = (
  data: TypeTree = [],
  checker: (arg0: unknown) => void
) => {
  return data.map(e => {
    if (e.children) {
      utilsMapTree(e.children, checker)
      checker(e)
    }
  })
}

/**
 * 查找字典
 *
 * @export
 * @param {TypeMap[]} dicts
 * @param {(string | number)} value
 * @returns {(TypeMap | TypePropnameObject)}
 */
export function utilsFindDict(
  dicts: TypeMap[],
  value: string | number,
  ignoreType?: boolean
): TypeMap | TypePropnameObject {
  value = ignoreType ? Number(value) : value
  const current = dicts.find((e) => e.key === value);
  return current || {};
}

/**
 * 分割字符串
 *
 * @param {*} str 要处理的数据
 * @param {*} [options={isNum: false, split: ','}] 配置项
 * @returns
 */
export const utilsStringSplit = (
  str?: string,
  options: { isNum?: boolean; split?: string } = {}
) => {
  options.isNum = options.isNum || false;
  options.split = options.split || ',';
  if (!str) return [];
  return str.split(options.split).map((e) => {
    if (options.isNum) return parseFloat(e);
    return e;
  });
};

export const utilsClassNamesBind = (styles: TypePropnameObject) => {
  const parseObject = (obj: TypePropnameObject) => {
    const keys = Object.keys(obj).filter((key) => obj[key])
    return parseArray(keys);
  };
  const parseString = (str: string): string | string[] => {
    if (str.indexOf(' ') > -1) {
      return parseArray(str.split(' '))
    }
    return str;
  };
  const parseArray = (list: TypeClassNameArg): string[] => {
    return list
      .map((e) => {
        if (utilsParseType(e) === 'string') {
          return parseString(e as string);
        }
        if (utilsParseType(e) === 'object') {
          return parseObject(e as TypePropnameObject);
        }
        if (utilsParseType(e) === 'array') {
          return parseArray(e as TypeClassNameArg);
        }

        return '';
      })
      .flat()
  };

  const getClass = (list: string[]) => {
    return list.map(e => styles[e]).join(' ')
  }

  return (...classNames: TypeClassNameArg) => {
    const classList = parseArray(classNames);
    return getClass(classList)
  };
};


export const utilsDeepClone = (data: unknown, _cache = {}): any => {
  if (!data) return data
  if (typeof data === 'object') {
    const result: any = Array.isArray(data) ? [] : {}
    for (const key in data) {
      const element = (data as {[props: string]: unknown})[key] ;
      result[key] = utilsDeepClone(element)
    }
    return result
  }
  return data
}


// const utilsDeepClone = (data, _cache = {}) => {
//   if (!data) return data
//   if (Array.isArray(data)) {
//     return data.map(e => utilsDeepClone(e))
//   }
//   if (typeof data === 'object' && data !== null) {
//     const result = {}
//     for (const key in data) {
//       const element = data[key]
//       result[key] = utilsDeepClone(element)
//     }
//     return result
//   }
//   return data
// }
