/*
 * @Author: zhao - 🍉
 * @Date: 2021-04-21 21:02:46
 * @LastEditors: zhao - 🍉
 * @LastEditTime: 2021-11-12 15:30:52
 * @Descripttion:
 */
import { i_article } from "src/typing";
import request from "../utils/request";




export async function fetchArticleList (data: {page?: number, pageSize?: number}) {
  return request('/article/list', { method: 'POST', data })
}

export async function fetchArticleInfo (data: { id: number }) {
  return request('/article/detail', { method: 'POST', data })
}

export async function fetchArticleUpdate (data: i_article) {
  return request('/article/update', { method: 'POST', data })
}
export async function fetchArticleDelete (data: {id: number}) {
  return request('/article/delete', { method: 'POST', data })
}
export async function fetchArticleCreate (data: i_article) {
  return request('/article/create', { method: 'POST', data })
}
