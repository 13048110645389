import { Modal, Form, Input, message, Spin, Select } from 'antd';
import React from 'react';
import { mapAccountStatus } from 'src/common/dict';
import {
  fetchDictAdd,
  fetchDictGet,
  fetchDictUpdate,
} from 'src/services/dict';

class DictUpdate extends React.Component {
  constructor() {
    super();
    this.state = {
      visible: false,
      loading: false,
      parent: {}
    };
  }

  handleSubmit() {
    this.FormRef.validateFields()
      .then((res) => {
        return this.postDictSave(res);
      })
      .then(() => {
        this.close();
        this.monitor.resolve();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  open(id, parent) {
    return new Promise((resolve, reject) => {
      this.id = id;
      this.setState({
        parent:  parent || {id: 0}
      })
      if (id) {
        this.postDictGet(id);
      }
      this.setState({ visible: true });
      this.monitor = { resolve, reject };
    });
  }

  close() {
    this.FormRef.resetFields();
    this.setState({ visible: false });

  }

  postDictGet(id) {
    this.setState({ loading: true });
    fetchDictGet({ id })
      .then((res) => {

        this.FormRef.setFieldsValue({
          name: res.data.data.name,
          value: res.data.data.value,
          status: res.data.data.status,
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  postDictSave(data) {
    let post = null;
    if (this.id) {
      post = fetchDictUpdate;
      data.id = this.id;
    } else {
      post = fetchDictAdd;
    }
    data.parent_id = this.state.parent.id
    return post(data).then((res) => {
      message.success('保存成功');
      return res.data;
    });
  }

  render() {
    const modalConfig = {
      title: '字典配置',
      visible: this.state.visible,
      onOk: this.handleSubmit.bind(this),
      onCancel: this.close.bind(this),
    };

    return (
      <Modal {...modalConfig}>
        <Spin spinning={this.state.loading}>
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 18 }}
            ref={(ref) => {
              this.FormRef = ref;
            }}
          >
            {this.state.parent.name && (
              <Form.Item label="父级"  rules={[{ required: true }]}>
                <Input placeholder="请输入" value={this.state.parent.name} disabled />
              </Form.Item>

            )}
            <Form.Item label="名称" name="name" rules={[{ required: true }]}>
              <Input placeholder="请输入" />
            </Form.Item>
            <Form.Item label="值" name="value">
              <Input placeholder="请输入" />
            </Form.Item>
            <Form.Item label="状态" name="status" rules={[{ required: true }]}>
              <Select placeholder="请选择">
                {mapAccountStatus.map((e) => (
                  <Select.Option key={e.key} value={e.key}>
                    {e.value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    );
  }
}

export default DictUpdate;
