import React from 'react'
import moment from "moment";
import { Badge, Button, Popconfirm, Space, Tooltip, Menu, message } from 'antd'
import { FormOutlined, DeleteOutlined, PlusOutlined, NodeIndexOutlined } from '@ant-design/icons';
import { mapAccountStatus } from 'src/common/dict'
import TableHelper from 'src/components/TableHelper'
import AuthButton from 'src/containers/AuthButton'
import { fetchDictDelete, fetchDictList } from 'src/services/dict'
import { utilsFindDict } from 'src/utils'
import DictUpdate from './update';
import classNames from 'classnames/bind';
import styles from './index.scss';
const cx = classNames.bind(styles);
class Dict extends React.Component{

  constructor() {
    super()
    this.state = {
      rootList: [],
      selected: null,
    }
  }

  componentDidMount() {
    this.postDictRootList()
  }

  handleUpdate = (id,parent) => {
    this.DictUpdateRef.open(id, parent).then(() => {
      this.TableHelperRef.postDataSource();
      if (!parent) {
        this.postDictRootList()
      }
    });
  };

  handleDelete = (id) => {
    this.postRuleDelete(id).then(() => {
      this.TableHelperRef.postDataSource();
    });
  };

  handleClick = (e) => {
    const { name } = e.item.props
    let selected = null
    if ( this.state.selected?.id !== e.key) {
      selected = {name, id: e.key}
    }
    this.setState({
      selected
    },() => {
      this.TableHelperRef.postDataSource();
    })
  }

  postDictRootList() {
    fetchDictList({parent_id: 0}).then(res => {
      this.setState({ rootList: res.data.data.list })
    })
  }

  postRuleDelete(id) {
    return fetchDictDelete({ id }).then(() => {
      message.success('删除成功');
    });
  }

  parseRequestData = (data) => {
    const { selected } = this.state
    data.parent_id = selected ? selected.id : 0
    return data
  }

  renderAction(record) {
    return (
      <Space>
         <Tooltip title="添加子值">
            <NodeIndexOutlined
              className="a"
              onClick={() => this.handleUpdate(null,record)}
            />
          </Tooltip>
        <AuthButton access="c-4-2">
          <Tooltip title="编辑">
            <FormOutlined
              className="a"
              onClick={() => this.handleUpdate(record.id, this.state.selected)}
            />
          </Tooltip>
        </AuthButton>
        <AuthButton access="c-4-3">
          <Tooltip title="删除">
            <Popconfirm
              title="确认删除吗"
              onConfirm={() => this.handleDelete(record.id)}
            >
              <span className="a">
                <DeleteOutlined />
              </span>
            </Popconfirm>
          </Tooltip>
        </AuthButton>
      </Space>
    );
  }

  renderRootMenu(list) {
    return list.map(e=> {
      return (
        <Menu.Item key={e.id} id={e.id} name={e.name} >
          {e.name}
        </Menu.Item>
      )
    })
  }
  render(){
    const columns = [
      { title: '名称', dataIndex: 'name' },
      { title: '状态', dataIndex: 'status', render: (text) =>{
        const current = utilsFindDict(mapAccountStatus, text, true)
        return <Badge text={current.value} color={current.color} />
      } },
      { title: '创建时间', dataIndex: 'create_time', render: (text) => {
        return moment(text).format('YYYY-MM-DD HH:mm');
      }, },
      {
        title: '操作',
        width: 80,
        dataIndex: 'action',
        render: (text, record) => this.renderAction(record),
      },
    ]
    const searchOptions = [
      {key: 'keyword', type: 'input', placeholder: '字典名称'}
    ]
    return (
      <div className={cx('containers')}>
        <div className={cx('left')}>
          <Menu selectedKeys={[this.state.selected?.id]} onClick={this.handleClick}  >
            {this.renderRootMenu(this.state.rootList)}
          </Menu >
        </div>
        <div className={cx('line')}></div>
        <div className={cx('right')}>
          <TableHelper
            ref={ref => {
              this.TableHelperRef = ref
            }}
            searchOptions={searchOptions}
            columns={columns}
            parseRequestData={this.parseRequestData}
            request={fetchDictList}
            scroll={{x: 500}}
          >
            <div slot="search">
              <AuthButton access="c-4-1">
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => this.handleUpdate(null, this.state.selected)}
                >
                  新增
                </Button>
              </AuthButton>
            </div>
          </TableHelper>
        </div>
        <DictUpdate
          ref={ref => {
            this.DictUpdateRef = ref
          }}
        />
      </div>
    )
  }
}

export default Dict
