/*
 * @Author: zhao - 🍉
 * @Date: 2021-09-04 15:21:41
 * @LastEditTime: 2021-12-05 16:45:49
 * @LastEditors: zhao - 🍉
 * @Description:
 * @FilePath: /xigua-blog-admin/src/pages/system/account/index.jsx
 */
import { Badge, Space, Tooltip, Button, Popconfirm, message } from 'antd';
import React from 'react';
import moment from 'moment';
import { mapAccountStatus } from 'src/common/dict';
import TableHelper from 'src/components/TableHelper';
import {
  fetchAccountDelete,
  fetchAccountList,
} from 'src/services/account';
import { PlusOutlined, FormOutlined, DeleteOutlined } from '@ant-design/icons';
import AuthButton from 'src/containers/AuthButton';
import AccountUpdate from './update';

const searchOptions = [
  { key: 'keyword', type: 'input', placeholder: '用户名/手机号' },
];
class Account extends React.Component {
  handleUpdate = (id) => {
    this.AccountUpdateRef.open(id).then(() => {
      this.TableHelperRef.postDataSource();
    });
  };

  handleDelete = (id) => {
    this.postAccountDelete(id);
  };

  postAccountDelete(id) {
    fetchAccountDelete({ id }).then(() => {
      message.success('删除成功');
      this.TableHelperRef.postDataSource();
    });
  }

  renderAction(record) {
    return (
      <Space>
        <AuthButton access="c-1-2">
          <Tooltip title="编辑">
            <FormOutlined
              className="a"
              onClick={() => this.handleUpdate(record.id)}
            />
          </Tooltip>
        </AuthButton>
        <AuthButton access="c-1-3">
          <Tooltip title="删除">
            <Popconfirm
              title="确认删除吗"
              onConfirm={() => this.handleDelete(record.id)}
            >
              <span className="a">
                <DeleteOutlined />
              </span>
            </Popconfirm>
          </Tooltip>
        </AuthButton>
      </Space>
    );
  }

  render() {
    const columns = [
      { title: '姓名', dataIndex: 'user_name' },
      { title: '手机', dataIndex: 'user_phone' },
      { title: '角色', dataIndex: 'role_name' },
      { title: '账号 ', dataIndex: 'user_account' },
      {
        title: '状态 ',
        dataIndex: 'status',
        render: (text) => {
          const current = mapAccountStatus.find((e) => e.key === text);
          if (!current) return '21231';
          return <Badge text={current.value} color={current.color} />;
        },
      },
      {
        title: '创建时间',
        dataIndex: 'createTime',
        render: (text) => {
          return moment(text).format('YYYY-MM-DD HH:mm');
        },
      },
      {
        title: '操作',
        dataIndex: 'action',
        width: 80,
        render: (_, record) => this.renderAction(record),
      },
    ];
    return (
      <div style={{ padding: 20 }}>
        <TableHelper
          ref={(ref) => {
            this.TableHelperRef = ref;
          }}
          request={fetchAccountList}
          columns={columns}
          searchOptions={searchOptions}
          scroll={{x: 500}}
        >
          <div slot="search">
            <AuthButton access="c-1-1">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => this.handleUpdate()}
              >
                新增
              </Button>
            </AuthButton>
          </div>
        </TableHelper>

        <AccountUpdate
          ref={(ref) => {
            this.AccountUpdateRef = ref;
          }}
        />
      </div>
    );
  }
}
export default Account;
