/*
 * @Author: zhao - 🍉
 * @Date: 2021-09-03 17:16:33
 * @LastEditTime: 2022-03-02 23:33:15
 * @LastEditors: zhao - 🍉
 * @Description:
 * @FilePath: /xigua-blog-admin/src/router/config.js
 */

import Article from 'src/pages/article';
import ArticleInfo from "src/pages/article/info";
import Access from 'src/pages/system/access';
import Account from 'src/pages/system/account';
import ActionLog from 'src/pages/system/actionLog';
import Dict from 'src/pages/system/dict';
import Role from 'src/pages/system/role';


const routes = [
  {
    path: '/app',
    children: [
      {
        title: '文章管理',
        path: 'article',
        icon: 'icon-project',
        access: 'a',
        children: [
          {
            title: '文章列表',
            path: 'list',
            access: 'a-1',
            actions: [
              { title: '新增', access: 'a-1-1' },
              { title: '修改', access: 'a-1-2' },
              { title: '删除', access: 'a-1-3' },
            ],
            component: Article,
          },
          {
            title: '文章详情',
            path: ':id',
            hidden: true,
            component: ArticleInfo,
          },
        ],
      },
      {
        title: '系统管理',
        path: 'system',
        access: 'c',
        icon: 'icon-system',
        children: [
          {
            title: '账号管理',
            path: 'account',
            access: 'c-1',
            actions: [
              { title: '新增', access: 'c-1-1' },
              { title: '修改', access: 'c-1-2' },
              { title: '删除', access: 'c-1-3' },
            ],
            component: Account,
          },

          {
            title: '角色管理',
            path: 'role',
            access: 'c-2',
            actions: [
              { title: '新增', access: 'c-2-1' },
              { title: '修改', access: 'c-2-2' },
              { title: '删除', access: 'c-2-3' },
            ],
            component: Role,
          },
          {
            title: '权限设置',
            path: 'access',
            access: 'c-3',
            actions: [{ title: '修改', access: 'c-3-1' }],
            component: Access,
          },
          {
            title: '数据字典',
            path: 'dict',
            access: 'c-4',
            actions: [
              { title: '新增', access: 'c-4-1' },
              { title: '修改', access: 'c-4-2' },
              { title: '删除', access: 'c-4-3' },
            ],
            component: Dict,
          },
          {
            title: '操作日志',
            path: 'actionLog',
            access: 'c-5',
            component: ActionLog,
          },
        ],
      },

    ],
  },
];

export default routes;
