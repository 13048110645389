/*
 * @Author: zhao - 🍉
 * @Date: 2021-11-09 10:37:36
 * @LastEditTime: 2021-11-10 18:26:38
 * @LastEditors: zhao - 🍉
 * @Description:
 * @FilePath: /xigua-blog-admin/src/services/open.ts
 */
import request from 'src/utils/request';

/**
 * 登录
 *
 * @export
 * @param {{ account: String, password: String }} data
 * @returns {{  id: 0, name: string, rightStr: string, token: string, validSeconds: 0, weixinLogo: string }}
 */
export function fetchOpenLogin(data: any) {
  return request({ url: '/user/login', data });
}
