/*
 * @Author: zhao - 🍉
 * @Date: 2021-11-09 10:36:30
 * @LastEditTime: 2021-11-30 16:16:20
 * @LastEditors: zhao - 🍉
 * @Description:
 * @FilePath: /xigua-blog-admin/src/store/modules/user.js
 */
import { fetchOpenLogin } from 'src/services/open';
import { fetchUserInfo, fetchUserLogout } from 'src/services/user';
import { authSetUser } from 'src/utils/auth';

const initState = {
  name: '',
  id: null,
  rightStr: '',
  validSeconds: 0,
  weixinLogo: null,
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'setUser':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const actionUserLogin = (params) => (dispatch) => {

  return fetchOpenLogin(params).then((res) => {
    const { token } = res.data.data;
    authSetUser({token})
    return fetchUserInfo()
  }).then((res) => {
    const user = res.data.data
    authSetUser(user);
    dispatch({ type: 'setUser', payload: user });
    return user;
  });
};

export const actionUserLogout = (params) => (dispatch) => {
  dispatch({ type: 'setUser', payload: initState });
  return fetchUserLogout(params).then(() => {
    authSetUser(initState);
  });
};

export default reducer;
