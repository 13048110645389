/*
 * @Author: zhao - 🍉
 * @Date: 2021-09-04 15:21:26
 * @LastEditTime: 2021-11-12 14:51:17
 * @LastEditors: zhao - 🍉
 * @Description:
 * @FilePath: /xigua-blog-admin/src/pages/system/access/index.jsx
 */
import React from 'react';
import classNames from 'classnames/bind';
import Right from 'src/containers/Right';
import { fetchRoleList, fetchRoleRightUpdate } from 'src/services/role';
import { Button, message } from 'antd';
import AuthButton from 'src/containers/AuthButton';
import styles from './index.scss';

const cx = classNames.bind(styles);
class Access extends React.Component {
  constructor() {
    super();
    this.state = {
      currentRole: {},
      roleList: [],
    };
  }

  componentDidMount() {
    this.postRoleList(() => {
      if (this.state.roleList.length > 0) {
        this.setState((state) => {
          const [currentRole] = state.roleList;
          state.currentRole = currentRole;
          return state;
        });
      }
    });
  }

  handleRoleChange = (e) => {
    this.setState({
      currentRole: e,
    });
  };

  handleRightChange = (e) => {
    this.setState((state) => {
      state.currentRole.rightStr = e;
      return state;
    });
  };

  handleSubmit = () => {
    const { currentRole } = this.state;
    this.postRightUpdate({
      id: currentRole.id,
      rightStr: currentRole.rightStr,
    });
  };

  getCurrent(index) {
    return this.roleListFrozen[index];
  }

  postRightUpdate(data) {
    return fetchRoleRightUpdate(data).then(() => {
      message.success('保存成功');
    });
  }

  postRoleList(cb) {
    fetchRoleList({ page: 1, pageSize: 1000 }).then((res) => {
      this.roleListFrozen = res.data.data.list;
      this.setState(
        {
          roleList: res.data.data.list,
        },
        cb
      );
    });
  }

  render() {
    return (
      <div className={cx('access-container')}>
        <div className={cx('role-list')}>
          {this.state.roleList.map((e) => (
            <div
              key={e.id}
              onClick={() => this.handleRoleChange(e)}
              className={cx('role-item', {
                on: this.state.currentRole.id === e.id,
              })}
            >
              {e.name}
            </div>
          ))}
        </div>
        <div className={cx('role-option')}>
          <Right
            value={this.state.currentRole.rightStr || ''}
            onChange={this.handleRightChange}
          />
          <AuthButton access="c-2-2">
            <Button type="primary" onClick={this.handleSubmit}>
              保存
            </Button>
          </AuthButton>
        </div>
      </div>
    );
  }
}

export default Access;
