/*
 * @Author: zhao - 🍉
 * @Date: 2021-09-03 14:13:03
 * @LastEditTime: 2022-03-02 23:59:10
 * @LastEditors: zhao - 🍉
 * @Description:
 * @FilePath: /xigua-blog-admin/src/pages/login/index.jsx
 */
import { Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import React from 'react';
import classNames from 'classnames/bind';
import { connectStore } from 'src/store';
import { actionUserLogin } from 'src/store/modules/user';
import routesConfig from 'src/router/config';
import loginBgSrc from 'src/assets/login/login-bg.jpeg';
import loginFormSrc from 'src/assets/login/login-form.png';
import logo0 from 'src/assets/login/logo-0.png';
import logo1 from 'src/assets/login/logo-1.png';
import logo2 from 'src/assets/login/logo-2.png';
import logo3 from 'src/assets/login/logo-3.png';
import styles from './index.scss';
const cx = classNames.bind(styles);

class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  onFinish = (values) => {
    this.setState({ loading: true });
    this.props
      .actionUserLogin(values)
      .then((res) => {
        this.props.dispatch({ type: 'app-setRoutes', payload: routesConfig });
        this.props.dispatch({
          type: 'app-setAccessRoutes',
          payload: { routesConfig, rightStr: res.rightStr },
        });

        this.props.history.push('/');
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  render() {
    return (
      <div
        className={cx('login-page-container')}
        style={{ backgroundImage: 'url(' + loginBgSrc + ')' }}
      >
        <div className={cx('logo-block')}>
          <img src={logo0} alt="" />
          <img src={logo3} alt="" />
          <img src={logo1} alt="" />
          <img src={logo2} alt="" />
        </div>
        <div className={cx('title-block')}>
          深海结构物安全评估与完整性诊断系统
        </div>
        <div className={cx('form-block')}>
          <div className={cx('bg')}>
            <img className={cx('img-1')} src={loginFormSrc} alt="" />
            <img className={cx('img-2')} src={loginFormSrc} alt="" />
            <img className={cx('img-3')} src={loginFormSrc} alt="" />
            <img className={cx('img-4')} src={loginFormSrc} alt="" />
          </div>

          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="account"
              rules={[{ required: true, message: '请输入用户名!' }]}
            >
              <Input prefix={<UserOutlined />} placeholder="用户名" />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{ required: true, message: '请输入密码!' }]}
            >
              <Input.Password prefix={<LockOutlined />} placeholder="密码" />
            </Form.Item>

            <Form.Item>
              <Button
                loading={this.state.loading}
                type="primary"
                className={cx('submit')}
                htmlType="submit"
              >
                提交
              </Button>
            </Form.Item>
          </Form>
        </div>

        <div className={cx('footer')}>
          <span className={cx('h2')}>©2018 All rights reserved. </span>
          <span className={cx('h2')}>科技部重点研发计划资助项目</span>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = {
  actionUserLogin,
};
export default connectStore(mapDispatchToProps)(Login);
