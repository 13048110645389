/*
 * @Author: zhao - 🍉
 * @Date: 2021-09-04 15:23:56
 * @LastEditTime: 2021-12-05 16:46:29
 * @LastEditors: zhao - 🍉
 * @Description:
 * @FilePath: /xigua-blog-admin/src/pages/system/actionLog/index.jsx
 */
import React from 'react';
import moment from 'moment';
import TableHelper from 'src/components/TableHelper';
import { fetchActionLogList } from 'src/services/actionLog';
import { mapActionInfo, mapActionType } from 'src/common/dict';

class ActionLog extends React.Component {
  render() {
    const columns = [
      {
        title: '操作类别',
        dataIndex: 'operationType',
        render: (val) => {
          const current = mapActionType.find((e) => e.key === val);
          if (!current) return '';
          return current.value;
        },
      },
      {
        title: '信息类别',
        dataIndex: 'infoType',
        render: (val) => {
          const current = mapActionInfo.find((e) => e.key === val);
          if (!current) return '';
          return current.value;
        },
      },
      { title: '备注', dataIndex: 'comment' },
      { title: '操作人', dataIndex: 'operatorName' },
      { title: '操作表', dataIndex: 'tableName' },
      { title: '操作行', dataIndex: 'rowId' },
      {
        title: '操作时间',
        dataIndex: 'createTime',
        render: (text) => {
          return moment(text).format('YYYY-MM-DD HH:mm');
        },
      },
    ];

    const searchOptions = [
      {
        key: 'operationType',
        type: 'select',
        options: mapActionType,
        placeholder: '操作类别',
      },
      { key: 'keyword', type: 'input', placeholder: '备注信息' },
      { key: 'beginDate', type: 'date', placeholder: '开始日期' },
      { key: 'endDate', type: 'date', placeholder: '结束日期' },
    ];
    return (
      <div style={{ padding: 20 }}>
        <TableHelper
          searchOptions={searchOptions}
          columns={columns}
          request={fetchActionLogList}
          scroll={{x: 500}}
        />
      </div>
    );
  }
}
export default ActionLog;
