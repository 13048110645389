/*
 * @Author: zhao - 🍉
 * @Date: 2021-04-16 19:55:45
 * @LastEditors: zhao - 🍉
 * @LastEditTime: 2021-11-12 17:57:27
 * @Descripttion:
 */
import React from 'react';
import zhCN from 'antd/lib/locale/zh_CN';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import './App.css';
import Router from './router';
import store from './store';
import { parseDevice } from './common/helper';

function App() {
  return (
   <Provider store={store}>
      <ConfigProvider locale={zhCN}>
        <Router />
      </ConfigProvider>
    </Provider>
  );
}

parseDevice(store)

export default App;
