import { Modal, Form, Input, message, Spin, Select } from 'antd';
import React from 'react';
import { mapAccountStatus } from 'src/common/dict';
import {
  fetchRoleAdd,
  fetchRoleGet,
  fetchRoleUpdate,
} from 'src/services/role';

class RoleUpdate extends React.Component {
  constructor() {
    super();
    this.state = {
      visible: false,
      loading: false,
    };
  }

  handleSubmit() {
    this.FormRef.validateFields()
      .then((res) => {
        return this.postRoleSave(res);
      })
      .then(() => {
        this.close();
        this.monitor.resolve();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  open(id) {
    return new Promise((resolve, reject) => {
      this.id = id;
      if (id) {
        this.postRoleGet(id);
      }
      this.setState({ visible: true });
      this.monitor = { resolve, reject };
    });
  }

  close() {
    this.FormRef.resetFields();
    this.setState({ visible: false });

  }

  postRoleGet(id) {
    this.setState({ loading: true });
    fetchRoleGet({ id })
      .then((res) => {

        this.FormRef.setFieldsValue({
          name: res.data.data.name,
          remark: res.data.data.remark,
          status: res.data.data.status,
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  postRoleSave(data) {
    let post = null;
    if (this.id) {
      post = fetchRoleUpdate;
      data.id = this.id;
    } else {
      post = fetchRoleAdd;
    }
    return post(data).then((res) => {
      message.success('保存成功');
      return res.data;
    });
  }

  render() {
    const modalConfig = {
      title: '角色',
      visible: this.state.visible,
      onOk: this.handleSubmit.bind(this),
      onCancel: this.close.bind(this),
    };

    return (
      <Modal {...modalConfig}>
        <Spin spinning={this.state.loading}>
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 18 }}
            ref={(ref) => {
              this.FormRef = ref;
            }}
          >
            <Form.Item label="名称" name="name" rules={[{ required: true }]}>
              <Input placeholder="请输入" />
            </Form.Item>
            <Form.Item label="备注" name="remark">
              <Input.TextArea placeholder="请输入" />
            </Form.Item>
            <Form.Item label="状态" name="status" rules={[{ required: true }]}>
              <Select placeholder="请选择">
                {mapAccountStatus.map((e) => (
                  <Select.Option key={e.key} value={e.key}>
                    {e.value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    );
  }
}

export default RoleUpdate;
