/*
 * @Author: zhao - 🍉
 * @Date: 2021-09-04 18:31:29
 * @LastEditTime: 2021-11-12 18:37:06
 * @LastEditors: zhao - 🍉
 * @Description:
 * @FilePath: /xigua-blog-admin/src/layout/components/LayoutHeader/index.jsx
 */
import React from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames/bind';
import { LogoutOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { connectStore } from 'src/store';
import { actionUserLogout } from 'src/store/modules/user';
import styles from './index.scss';
import LayoutHeaderMenu from './Menu';

const cx = classNames.bind(styles);
class LayoutHeader extends React.Component {
  handleLogout = () => {
    this.props.actionUserLogout();
    this.props.history.push('/login');
  };

  handleSlideChange = () => {
    if (this.props.onSlideChange) {
      this.props.onSlideChange()
    }
  }

  render() {

    return (
      <div className={cx('layout-header-container')}>
        <div className={cx('menu-block')}>
          {this.props.app.isMobile && (
            <span className={cx('slide-btn')} onClick={this.handleSlideChange}>
              <MenuUnfoldOutlined />
            </span>
          )}
          <LayoutHeaderMenu accessRoutes={this.props.app.accessRoutes} />
        </div>
        <div style={{ paddingRight: 20, cursor: 'pointer' }}>
          <LogoutOutlined style={{ marginRight: 10 }} />
          <span onClick={this.handleLogout} aria-hidden="true">
            退出
          </span>
        </div>
      </div>
    );
  }
}
export default connectStore({ actionUserLogout })(withRouter(LayoutHeader));
