/*
 * @Author: zhao - 🍉
 * @Date: 2021-04-21 21:05:13
 * @LastEditors: zhao - 🍉
 * @LastEditTime: 2021-11-30 16:15:41
 * @Descripttion:
 */
import request from "../utils/request";

export function fetchUserRegister(data: { user_name: string, user_pass: string }) {
  return request('/user/register', { method: 'POST', data })
}

export function fetchUserLogin(data: { user_name: string, user_pass: string }) {
  return request('/user/login', { method: 'POST', data })
}
export function fetchUserLogout() {
  return request('/user/logout', { method: 'POST' })
}
export function fetchUserInfo() {
  return request('/user/info', { method: 'POST' })
}
