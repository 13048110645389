/*
 * @Author: zhao - 🍉
 * @Date: 2021-05-05 15:21:39
 * @LastEditors: zhao - 🍉
 * @LastEditTime: 2021-12-31 18:41:14
 * @Descripttion:
 */
import React,{ useMemo } from "react";

import { Editor } from "@bytemd/react";
import highlight from "@bytemd/plugin-highlight";
import gfm from "@bytemd/plugin-gfm";

import 'bytemd/dist/index.min.css';
import  "src/styles/bytemd.scss";
import { fetchFileUpload } from "src/services/file";
const Bytemd = (props: {value?: string, onChange?: any}) => {
  const plugins = useMemo(() => {
    return [highlight(), gfm()]
  }, [])
  const handleUpload = async (files: Array<File>) => {
    const data = new FormData()
    data.set('file', files[0])

    return fetchFileUpload(data).then(res => {
      const {data} = res.data
      return [{ title: data.filename, alt: data.filename, url: data.filepath }]
    })
  }
  return <Editor uploadImages={handleUpload} plugins={plugins} value={props.value || ''} onChange={props.onChange} />
}


export default Bytemd
