/*
 * @Author: zhao - 🍉
 * @Date: 2021-11-09 10:22:18
 * @LastEditTime: 2021-11-30 16:10:00
 * @LastEditors: zhao - 🍉
 * @Description:
 * @FilePath: /xigua-blog-admin/src/utils/auth.ts
 */

// 开发环境使用持久缓存, 避免调试时重复登录
const storage =
  process.env.NODE_ENV === 'development' ? localStorage : sessionStorage;

export const authGetUser = () => {
  const t = storage.getItem('token');
  if (t === null) return {};
  return JSON.parse(t);
};

/**
 * 设置用户信息
 * @param data 用户数据
 */
export const authSetUser = (data = {}) => {
  const currentUser = authGetUser()
  const user = {...currentUser, ...data}
  const token = JSON.stringify(user);
  storage.setItem('token', token);
};


export const authClearUser = (data = {}) => {
  const token = JSON.stringify({});
  storage.setItem('token', token);
};
