/*
 * @Author: zhao - 🍉
 * @Date: 2021-11-12 16:25:35
 * @LastEditTime: 2021-11-12 16:33:43
 * @LastEditors: zhao - 🍉
 * @Description:
 * @FilePath: /xigua-blog-admin/src/services/dict.ts
 */

import request from "src/utils/request"



/**
* 字典-字典列表
*
* @export
* @param {{ page: number, pageSize?: number, keyword?: string }} data
*/
export function fetchDictList(data: any, option = {}) {
  return request({ url: '/dict/list', data, ...option })
}

/**
* 字典-获取字典详情
*
* @export
* @param {{id: number}} data
*/
export function fetchDictGet(data: any, option = {}) {
  return request({ url: '/dict/detail', data, ...option })
}

/**
* 字典-更新字典
*
* @export
* @param {*} data
*/
export function fetchDictUpdate(data: any, option = {}) {
  return request({ url: '/dict/update', data, ...option })
}

/**
* 字典-新增字典
*
* @export
* @param {*} data
*/
export function fetchDictAdd(data: any, option = {}) {
  return request({ url: '/dict/create', data, ...option })
}

/**
* 字典-删除字典
*
* @export
* @param {{id: number}} data
*/
export function fetchDictDelete(data: any, option = {}) {
  return request({ url: '/dict/delete', data, ...option })
}

