/*
 * @Author: zhao - 🍉
 * @Date: 2021-04-16 20:56:45
 * @LastEditors: zhao - 🍉
 * @LastEditTime: 2021-12-08 17:14:59
 * @Descripttion:
 */
import React, { useState, useEffect } from "react";
import { Divider, Table, TablePaginationConfig, Popconfirm, message, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { parseTime } from "src/utils/date";
import { fetchArticleList, fetchArticleDelete } from "src/services/article";
import TableHelper from "src/components/TableHelper";
const ArticleList  = (props: any) => {

  const [dataSource, setdataSource] = useState([]);
  const [page, setpage] = useState(1)
  const [total, settotal] = useState(0)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    postArticleList()
  }, [page]);

  const postArticleList = () => {
    setLoading(true)
    fetchArticleList({page, pageSize: 10}).then(res => {
      setdataSource(res.data.data.list)
      settotal(res.data.data.total)
    }).finally(() => {
      setLoading(false)
    })
  }
  const postArticleDelete = (id: number) => {
    return fetchArticleDelete({id}).then(() => {
      message.success('已删除');
    }).catch(() => {
      message.error('删除失败请重试！');
    })
  }
  const handleAdd = (id: number) => {
    props.history.push('/app/article/' + id)
  }
  const handleTableChange = (e: TablePaginationConfig) => {
    setpage(e.current || 1)
  }
  const handleDelete = (id: number) => {
    postArticleDelete(id).then(() => {
      postArticleList()
    })
  }

  const columns = [
    { title: '标题', dataIndex: 'title' },
    { title: '浏览次数', dataIndex: 'view_count' , width:100, },
    { title: '创建时间', dataIndex: 'create_time', width:170,  render:(text: Date) => {
      return parseTime(text)
    } },
    { title: '操作', dataIndex: 'actions',  width:110,  render: (_: any, record:  { id: number; }) => [
      <a  onClick={() => handleAdd(record.id)} key={1}>编辑</a>,
      <Divider key={2} type="vertical"/>,
      <Popconfirm key={3}  title="删除?" onConfirm={() => handleDelete(record.id)}>
        <a>删除</a>
      </Popconfirm>
    ] }
  ]

  const searchOptions = [
    { key: 'keyword', type: 'input', placeholder: '标题' },
  ];

    return <div  style={{ padding: 20 }}>
      <TableHelper
        request={fetchArticleList}
        columns={columns}
        loading={loading}
        searchOptions={searchOptions}
        scroll={{x: 500}}
      >

        <div slot="search">
          <Button type="primary" icon={<PlusOutlined />} onClick={() => handleAdd(0)}>添加</Button>
        </div>
      </TableHelper>
      {/* <Table
        key="id"
        columns={columns}
        loading={loading}
        dataSource={dataSource}
        pagination={{current: page, pageSize: 10, total}}
        onChange={handleTableChange}
        scroll={{x: 900}}
      /> */}
    </div>
}

export default ArticleList
